// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './List.module.scss';

const List = ({list}) => (
  <div className={styles['list']}>
    <ul className={styles['list__list']}>
      {list.map((item) => (
        <li className={styles['list__list-item']} key={item}>
          <Link to={`/${kebabCase(item)}/`} className={styles['list__list-item-link']}>
            {item}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default List;
